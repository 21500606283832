<script >
import { mapActions } from "vuex";
import UserService from "../common/mixins/User";

export default {
  mounted() {
    this.logout();
  },
  mixins: [UserService],
  methods: {
    ...mapActions("auth", {
      logoutUser: "logoutUser",
    }),
    async logout() {
      await this.logoutUser();
      this.$router.push("/login");
    },
  },
};
</script>